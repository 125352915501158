.custom-swal-popup {
    margin: 10% auto; 
    width: 90%; 
    max-width: 900px; 
    box-sizing: border-box;
  }
  
  .custom-swal-popup img {
    height: auto;
    max-height: 70vh; 
    object-fit: cover;
  }
  
  @media (max-width: 1200px) {
    .custom-swal-popup {
      margin: 5% auto;
      width: 85%;
    }
  }
  
  @media (max-width: 992px) {
    .custom-swal-popup {
      margin: 5% auto;
      width: 90%;
    }
  }
  
  @media (max-width: 768px) {
    .custom-swal-popup {
      margin: 5% auto;
      width: 95%;
    }
  }
  
  @media (max-width: 576px) {
    .custom-swal-popup {
      margin: 5% auto;
      width: 100%;
    }
  
    .custom-swal-popup img {
      max-height: 70vh;
    }
  }
  
/* style.css */
.badge-container {
    position: relative;
    display: inline-block;
  }
  
  .badge {
    position: absolute;
    top: -15px;
    right: -28px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  
  